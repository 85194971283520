/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    'common': {
      init: function() {

        // Initiate WOW.js
        new WOW().init();

        // Smooth Scroll Links
        $('.smoothscroll').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top-80
              }, 1000);
              return false;
            }
          }
        });

        // Tool Tips
        $(function () {
          $('[data-toggle="tooltip"]').tooltip();
        });

        // Map Flags Overlay
        $('#map-overlay-link').on('click', function() {
          $('.site-header__dropdown').slideToggle('slow');
          $('#overlay').fadeToggle();
        });

        // Search dropdown
        $("#search-button").click(function(e){
          $(".search").slideToggle('slow');
          $(".search-backdrop").fadeToggle();
          e.preventDefault;
        });

        $("#close-search").click(function(){
          $(".search").slideToggle('slow');
          $(".search-backdrop").fadeToggle();
        });

        // Testimonial Slider
        var mySwiper = new Swiper ('.swiper-container--testimonials', {
          direction: 'horizontal',
          loop: false,
          slidesPerView: 4,
          slidesPerGroup: 4,
          autoplay: 3000,
          speed: 2000,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          breakpoints: {
            480: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 3,
            }
          }
        });

        // Scroll to top button
        $(document).scroll(function() {
          var y = $(this).scrollTop();
          if (y > 400) {
            $('#scrollToTop').fadeIn();
          } else {
            $('#scrollToTop').fadeOut();
          }
        });

        $("#scrollToTop").click(function() {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        });
      },
      finalize: function() {

        var burger, header;
        burger = document.querySelector('.burger-container');
        header = document.querySelector('.site-header--mobile');

        burger.onclick = function () {
          header.classList.toggle('menu-opened');
        };

      }
    },

    'home': {
      init: function() {

        // Image slider on homepage
        var mySwiper = new Swiper ('.swiper-container--homepage', {
          direction: 'horizontal',
          loop: false,
          slidesPerView: 1,
          autoplay: 1800,
          effect: 'slide',
          pagination: '.swiper-pagination',
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'my_account': {
      init: function() {
        
        $('.date-submitted').hide();        

        $('a.acf-button').click(function(){

          var now = new Date();
          var day = ("0" + now.getDate()).slice(-2);
          var month = ("0" + (now.getMonth() + 1)).slice(-2);
          var today = (day)+"/"+(month)+"/"+now.getFullYear();

          $('.acf-row').each(function(i, obj) {            
            var dataid = $(this).data("id");

            if (!(dataid == 0 || dataid == 1 || dataid == 2 || dataid == 3 || dataid == 4 || dataid == 5 || dataid == 6 || dataid == 7 || dataid == 8 || dataid == 9)) {            
              $(this).find(".date-submitted input").val(today);
            }

          });

        });        

      },
      finalize: function() {
      }
    },

    'single_product': {
      init: function() {

        $.fn.inView = function(inViewType){
          var viewport = {};
          viewport.top = $(window).scrollTop();
          viewport.bottom = viewport.top + $(window).height();
          var bounds = {};
          bounds.top = this.offset().top;
          bounds.bottom = bounds.top + this.outerHeight();
          switch(inViewType){
            case 'bottomOnly':
              return ((bounds.bottom <= viewport.bottom) && (bounds.bottom >= viewport.top));
            case 'topOnly':
              return ((bounds.top <= viewport.bottom) && (bounds.top >= viewport.top));
            case 'both':
              return ((bounds.top >= viewport.top) && (bounds.bottom <= viewport.bottom));
            default:
              return ((bounds.top >= viewport.top) && (bounds.bottom <= viewport.bottom));
          }
        };

        // The fixed product navbar that fades in on scroll
        $(window).scroll(function() {
          var scroll = $(window).scrollTop();

          if (scroll >= 680) {
            $(".product__navbar").slideDown();
          } else {
            $(".product__navbar").slideUp();
            console.log("slide up");
          }

          if (scroll >= 750) {
            $(".product__navbar").addClass("height");
          } else {
            $(".product__navbar").removeClass("height");
          }
        });

        // Countdown
        function count() {
          $('.counter__number').each(function () {
            var $this = $(this);
            var countTo = $this.attr('data-count');

            $({ countNum: $this.text() }).animate({
              countNum: countTo,
            },
            {
              duration: 2000,
              easing: 'linear',
              step: function() {
                $this.text(Math.floor(this.countNum));
              },
              complete: function() {
                $this.text(this.countNum);
              },
            });
          });
        }

        if($("#counter").length !== 0) {
          var waypoint = new Waypoint({
            element: document.getElementById('counter'),
            handler: function(direction) {
              if (direction === 'down') {
                count();
              }
            },
            offset: '60%'
          });
        }



        // Mobile Fixed Nav
        $('.product--mobile__nav').scrollToFixed();

      },
      finalize: function() {

        // Product Image Sliders
        $('#carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 94,
          itemMargin: 5,
          asNavFor: '#slider'
        });

        $('#slider').flexslider({
          animation: "fade",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#carousel"
        });
      }
    },

    'archive': {
      init: function() {

        // Product Filtering
        var mixer = mixitup('.mixitup', {
          "animation": {
            "duration": 243,
            "nudge": false,
            "reverseOut": false,
            "effects": "fade"
          },
          multifilter: {
            enable: true,
            logicWithinGroup: 'and'
          },
          controls: {
            enable: false
          }
        });

        // Show/hide the Product filter
        $('.product-advisor-tab').click(function(e){
          $('.product-filter').slideToggle('slow');
          $(this).toggleClass('active');
          e.preventDefault();
        });


      },
      finalize: function() {

      }
    },

    'dealer': {
      init: function(){
        // Product video Sliders
        $('#note-carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 150,
          itemMargin: 15,
          asNavFor: '#note-slider'
        });

        $('#note-slider').flexslider({
          animation: "fade",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#note-carousel"
        });


        $('#coin-carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 150,
          itemMargin: 15,
          asNavFor: '#coin-slider'
        });

        $('#coin-slider').flexslider({
          animation: "fade",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#coin-carousel"
        });


        $('#counterfeit-carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 150,
          itemMargin: 15,
          asNavFor: '#counterfeit-slider'
        });

        $('#counterfeit-slider').flexslider({
          animation: "fade",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#counterfeit-carousel"
        });


        $('#scales-carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 150,
          itemMargin: 15,
          asNavFor: '#scales-slider'
        });

        $('#scales-slider').flexslider({
          animation: "fade",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#scales-carousel"
        });
      },
      finalize: function(){

      }
    },

    'how_zzap_counterfeit_detectors_work': {
      init: function(){
        $('#chart').highcharts({
            chart: {
                type: 'spline'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels:
                {
                    enabled: false
                },
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                alternateGridColor: null,
                plotBands: [{ // Light air
                    from: 0.3,
                    to: 1.5,
                    color: '#fff',
                    label: {
                        text: '',
                        style: {
                            color: '#fff'
                        }
                    }
                }, { // Light breeze
                    from: 1.5,
                    to: 3.3,
                    color: '#fff',
                    label: {
                        text: '',
                        style: {
                            color: '#fff'
                        }
                    }
                }, { // Gentle breeze
                    from: 3.3,
                    to: 5.5,
                    color: '#fff',
                    label: {
                        text: '',
                        style: {
                            color: '#fff'
                        }
                    }
                }, { // Moderate breeze
                    from: 5.5,
                    to: 8,
                    color: '#fff',
                    label: {
                        text: '',
                        style: {
                            color: '#606060'
                        }
                    }
                }, { // Fresh breeze
                    from: 8,
                    to: 11,
                    color: '#76BA6F',
                    label: {
                        text: '',
                        style: {
                            color: '#606060'
                        }
                    }
                }, { // Strong breeze
                    from: 11,
                    to: 14,
                    color: 'rgba(0, 0, 0, 0)',
                    label: {
                        text: '',
                        style: {
                            color: '#606060'
                        }
                    }
                }, { // High wind
                    from: 14,
                    to: 15,
                    color: '#76BA6F',
                    label: {
                        text: '',
                        style: {
                            color: '#606060'
                        }
                    }
                }]
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                spline: {
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 5
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    pointInterval: 3600000, // one hour
                    pointStart: Date.UTC(2015, 4, 31, 0, 0, 0)
                }
            },
            series: [{
                name: 'Counterfeit Banknotes In Circulation',
                data: [0.2, 1.2, 1, 1.2, 2, 3.5]

            }],
            navigation: {
                menuItemStyle: {
                    fontSize: '10px'
                }
            }
        });
      }
    },

    'support': {
      init: function(){

        // Driver download
        $(".driver-category").click(function(){
          $("#selectProduct").slideUp();
          var catID = $(this).attr('id');
          console.log(catID);

          setTimeout(function (){
            $("#productsAjax").load("http://zzap.secure-dev.co.uk/driver-download?category="+catID);
            setTimeout(function (){
              $("#selectProduct").slideDown();
              $('html, body').animate({
                scrollTop: $("#selectProduct").offset().top
              }, 750);
            }, 750);

            $("#selectDownloads").slideUp();
          }, 200);
        });
      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
